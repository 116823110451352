import { configureStore } from '@reduxjs/toolkit'

import { CombinedState, rootReducer } from './reducer/rootReducer'
import { CombinedSearchState } from './types'

export { useDispatch, useSelector } from 'react-redux'

export const selectors = {
  getSidebarOpen: (state: CombinedState) => state.app.sidebarOpen,
  getWorkspaceBadgeCount: (state: CombinedState) =>
    state.app.workspaceBadgeCount,
  getImageSets: (state: CombinedState) => state.app.imageSets,
  getProjectStatus: (state: CombinedState) => state.app.projectStatus,
  getMenus: (state: CombinedState) => state.app.menus,
  getIsMiradorMaximized: (state: CombinedState) => state.app.isMiradorMaximized,
}

export const searchSelectors = {
  getModifiedFilterQuery: (state: CombinedSearchState) =>
    state.search.modifiedFilterQuery,
  getShowFilterList: (state: CombinedSearchState) =>
    state.search.showFilterList,
  getHitListVariant: (state: CombinedSearchState) =>
    state.search.hitListVariant,
  getSelectedResources: (state: CombinedSearchState) =>
    state.search.selectedResources,
  getExtendedSearchGroups: (state: CombinedSearchState) =>
    state.extendedSearch.extendedSearchGroups,
  getExtendedSearchListOperators: (state: CombinedSearchState) =>
    state.extendedSearch.extendedSearchGroups[0].groupLogicOperators,
  getSearchTerm: (state: CombinedSearchState) => state.search.searchTerm,
  getSearchField: (state: CombinedSearchState) => state.search.searchField,
  getSearchParams: (state: CombinedSearchState) => state.search.searchParams,
  getAuthorityId: (state: CombinedSearchState) => state.search.authorityId,
}

export const makeStore = (initialState?: any) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true,
  })
