import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { ErrorPage } from 'src/components/shared/ErrorPage'
import { Grid } from 'src/components/shared/Grid'
import { searchActions } from 'src/contexts/actions/searchActions'
import { useByNormDataId, useHspObjectsByQuery } from 'src/contexts/discovery'
import { useParsedSearchParams } from 'src/utils/searchparams'

import { gndTypePlaces } from '../../config'
import { useSearchTranslation } from '../../utils'
import { NormKodLink } from './NormKodLink'
import { NormObject } from './NormObject'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    scrollMarginTop: '350px',
  },
  authItem: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  authItemCount: {
    background: theme.palette.warmGrey.main,
    flexGrow: 1,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(3.5),
    },
  },
}))

export function NormOverview() {
  const cls = useStyles()
  const { searchT } = useSearchTranslation()
  const { authorityfileid } = useParsedSearchParams()
  const { hash } = useLocation()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { data: authItem, error: authItemError } =
    useByNormDataId(authorityfileid)
  const params = {
    q: `${authorityfileid}`,
    fq: undefined,
    start: undefined,
    rows: undefined,
  }
  const { data: searchResult } = useHspObjectsByQuery(params)

  const { numFound } = searchResult?.metadata ?? {
    numFound: 0,
  }

  useEffect(() => {
    if (hash === '#authority') {
      const desc = document.getElementById('authorityFileResult')
      desc?.scrollIntoView()
      desc?.focus()
    } else {
      window.scrollTo(0, 0)
    }
  }, [authItem, hash])

  useEffect(() => {
    if (authItem && !gndTypePlaces.includes(authItem.typeName)) {
      navigate(`/search?q=%22${authorityfileid}%22`)
      dispatch(searchActions.setAuthorityId(''))
    }
  }, [authItem])

  useEffect(() => {
    if (authorityfileid) {
      dispatch(searchActions.setAuthorityId(authorityfileid))
    }
  }, [authorityfileid])

  if (authItemError) return <ErrorPage errorMessage={searchT('failed')} />
  if (!authItem)
    return <p data-testid="discovery-norm-overview">{searchT('loading')}</p>

  return (
    <div className={cls.root}>
      <div className={cls.authItem}>
        <Grid>
          <NormObject authItem={authItem} numFound={numFound.toString()} />
        </Grid>
      </div>
      <div className={cls.authItemCount}>
        <Grid>
          <NormKodLink id={authItem.id} numFound={numFound.toString()} />
        </Grid>
      </div>
    </div>
  )
}
