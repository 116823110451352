import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import LaunchIcon from '@material-ui/icons/Launch'

import SinglePageWrapper from 'src/components/search/shared/SinglePageWrapper'
import { useSearchTranslation } from 'src/components/search/utils'
import { AuthItemProps } from 'src/contexts/discovery'

import { NormCard } from './NormCard'
import { NormResourceLinks } from './NormResourceLinks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
  },
  summary: {
    marginTop: theme.spacing(2),
  },
  gndIdentifier: {
    display: 'flex',
  },
  gndLink: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'break-spaces',
  },
  btnMenu: {
    marginTop: theme.spacing(2),
    flexShrink: 0,
  },
}))

interface NormObjectProps {
  authItem: AuthItemProps
  numFound: string
}

export function NormObject(props: Readonly<NormObjectProps>) {
  const { authItem, numFound } = props
  const { typeName, gndId, preferredName, id } = authItem

  const { searchT } = useSearchTranslation()
  const cls = useStyles()

  return (
    <div className={cls.root}>
      <SinglePageWrapper>
        <div>
          <Typography className={cls.title} variant="h1">
            {preferredName}
          </Typography>
          <div className={cls.summary}>
            <Typography component="p">
              {searchT('normOverview', 'typeName')}:{' '}
              {searchT('normOverview', 'types', 'Place')}{' '}
              {typeName !== 'Place' &&
                '(' + searchT('normOverview', 'types', typeName) + ')'}
            </Typography>
            <Typography component="p" className={cls.gndIdentifier}>
              {searchT('normOverview', 'gndIdentifier')}:
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className={cls.gndLink}
                href={`https://d-nb.info/gnd/${gndId}`}
              >
                {gndId} <LaunchIcon fontSize="small" />
              </Link>
            </Typography>
            <Typography component="p">
              {searchT('normOverview', 'id')}: {id}
            </Typography>
          </div>
        </div>
        <div className={cls.btnMenu}>
          <NormResourceLinks
            numFound={numFound}
            permalink={`https://handschriftenportal.de/?id=${id}`}
          />
        </div>
      </SinglePageWrapper>
      <NormCard authItem={authItem} />
    </div>
  )
}
