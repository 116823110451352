import React, { useRef } from 'react'

import { Navigation } from 'src/components/navigation'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function AuthorityFiles() {
  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'

  useSetupModulesForPage()
  useTrackPageView('AuthorityFiles')

  return (
    <PageNameProvider value="authorityFiles">
      <Navigation main={mainContainer.current} module="authorityFiles" />
    </PageNameProvider>
  )
}
