import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  ParsedSearchParams,
  useParsedSearchParams,
} from 'src/utils/searchparams'

import { ExtendedSearchView } from './ExtendedSearchView'
import { ListView } from './ListView'
import { Overview } from './Overview'

export function Search() {
  const { hspobjectid }: ParsedSearchParams = useParsedSearchParams()

  const { pathname } = useLocation()
  let children = null

  if (hspobjectid) {
    children = <Overview />
  } else if (pathname === '/search/extended') {
    children = <ExtendedSearchView />
  } else {
    children = <ListView />
  }

  return <div id="hsp-search-main">{children}</div>
}
